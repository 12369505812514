<template>
  <div class="h-full w-full flex flex-row h-full">
    <div class="h-full w-full overflow-y-hidden">
      <div class="bg-white w-full">
        <item-header v-if="headerVisible"></item-header>
        <nav class="-mb-px flex">
          <div @click="select('customer')" :class="[currentTab=='customer' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-4 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            <i class="fal fa-person"></i> Customer
          </div>
          <div @click="select('published')" :class="[currentTab=='published' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-4 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            <i class="fal fa-cars"></i> Catalog
          </div>
          <div @click="select('proposal')" :class="[currentTab=='proposal' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-8 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            Proposal
          </div>
          <div @click="select('sale')" :class="[currentTab=='sale' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-8 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            Sale
          </div>
          <div @click="select('delivery')" :class="[currentTab=='delivery' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-8 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            Delivery
          </div>
          <div @click="select('documents')" :class="[currentTab=='documents' ? 'text-blue-600 border-b-2 border-blue-500': 'border-b-2 border-transparent text-gray-500','whitespace-no-wrap ml-8 py-4 px-1 font-medium text-sm leading-5 focus:outline-none focus:text-blue-800 focus:border-blue-700 cursor-pointer']" aria-current="page">
            Documents
          </div>
        </nav>
      </div>
      <published v-if="currentTab=='published'"></published>
      <proposal v-if="currentTab=='proposal'"></proposal>
    </div>
  </div>
</template>

<script>
import ItemHeader from "@/sales/views/sales/Header";
import Published from "@/sales/views/sales/Catalog";
import Proposal from "@/sales/views/sales/Proposal";

export default {
  name: 'item',
  components: {
    'published': Published,
    'proposal': Proposal,
    'item-header': ItemHeader,
  },
  data() {
    return {
      headerVisible: false,
      currentTab: 'published'
    }
  },
  methods: {
    select(tab) {
      this.currentTab = tab;
    }
  }

};
</script>
