<template>
  <div class="h-full flex flex-row" style="max-width:1830px;">
    <div class="w-2/3 px-2">
      <div class="rounded-lg border border-gray-100 px-2 pb-2 flex flex-row justify-between">
        <div class="flex flex-col">
          <div class="text-blue-700 text-lg font-bold p-1">Sale</div>
          <div class="w-full flex flex-row justify-between">
            <div class="w-40">
              <img src="https://cdn.usedcars.center/1024x768/73/70/06/73700681581115578184257966404212466479471143132413392011108538528200740574587.jpg" />
            </div>
            <div class="px-2 flex flex-col text-xs">
              <div class="text-blue-500 text-base font-medium">Audi A4</div>
              <div class="text-gray-700 text-sm pb-2">2.0 TDI highline Sports turbo</div>
              <div class="text-gray-700">
                <i class="fal fa-tachometer-alt-fast text-gray-300"></i> <span class="ml-1 mr-2">114.000 km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-spreadsheet text-gray-300"></i> <span class="ml-1 mr-2">14/6/2020</span>
              </div>
              <div class="text-gray-700 pt-1">
                <i class="fal fa-gas-pump text-gray-300"></i> <span class="ml-1 mr-2">Benzine</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-joystick text-gray-300"></i> <span class="ml-1 mr-2">Manual</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">4 doors</span>
              </div>
              <div class="text-gray-700 pt-1">
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">5 places</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">117 gr/km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">Euro 6</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-48 flex flex-col justify-between mt-2">
          <div class="bg-blue-200 text-blue-600 flex flex-row rounded-tl-lg rounded-br-lg">
            <div class="pl-2 py-3 text-2xl leading-4">€</div>
            <div class="w-full p-2 flex flex-col text-right">
              <div class="text-xl leading-5 font-bold">14.900</div>
              <div class="leading-4 text-xs">incl.</div>
            </div>
          </div>
          <div class="bg-white-200 text-gray-600 flex flex-row rounded-tl-lg rounded-br-lg ">
            <div class="pl-2 py-3 text-2xl leading-4">€</div>
            <div class="mt-1 w-full flex flex-col items-end">
              <div class="relative rounded-sm shadow-sm w-36">
                <input type="text" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300" placeholder="Intervention">
              </div>
            </div>
          </div>
          <div class="bg-blue-500 text-white flex flex-row rounded-tl-lg rounded-br-lg">
            <div class="pl-2 py-3 text-2xl leading-4">€</div>
            <div class="w-full p-2 flex flex-col text-right">
              <div class="text-xl leading-5 font-bold">14.900</div>
              <div class="leading-4 text-xs">incl.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="rounded-lg border border-gray-100 bg-gray-100 pl-2 pr-1 pb-2 flex flex-row justify-between mt-2">
        <div class="flex flex-col">
          <div class="text-blue-700 text-xl font-bold py-2">Products & Services</div>
          <div class="flex flex-col">
            <div class="relative rounded-sm shadow-sm w-72">
              <input type="text" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300" placeholder="Name">
            </div>
            <div class="flex flex-row pt-2 justify-end">
              <div class="relative rounded-sm shadow-sm w-16">
                <input type="text" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300" placeholder="Qty">
              </div>
              <div class="relative rounded-sm shadow-sm w-28 ml-2">
                <input type="text" class="form-input block w-full sm:text-sm sm:leading-tight font-bold text-blue-600 rounded border-gray-300" placeholder="Price">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400 text-xs">
                  <i class="fal fa-euro-sign"></i>
                </div>
              </div>
              <div class="relative rounded shadow w-24 ml-2 bg-blue-500 text-white flex flex-row justify-center items-center hover:bg-blue-600 cursor-pointer">
                <i class="fal fa-plus"></i> &nbsp; Add
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-1 ml-2 px-1 py-1 rounded-lg bg-white overflow-y-auto" style="max-height: 120px;">
          <div class=" bg-blue-100 rounded-md flex flex-row mb-1">
            <div class="w-12 px-1 py-1">
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-times"></i></span>
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-pen"></i></span>
            </div>
            <div class="w-48 border-l border-blue-300 px-2 py-1">Vloertapijten </div>
            <div class="w-18 border-l border-blue-300 px-2 py-1 font-bold text-blue-600 text-right">220 €</div>
          </div>
          <div class="bg-blue-100 rounded-md flex flex-row mb-1">
            <div class="w-12 px-1 py-1">
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-times"></i></span>
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-pen"></i></span>
            </div>
            <div class="w-48 border-l border-blue-300 px-2 py-1">Levering </div>
            <div class="w-18 border-l border-blue-300 px-2 py-1 font-bold text-blue-600 text-right">included</div>
          </div>
          <div class="bg-blue-100 rounded-md flex flex-row mb-1">
            <div class="w-12 px-1 py-1">
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-times"></i></span>
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-pen"></i></span>
            </div>
            <div class="w-48 border-l border-blue-300 px-2 py-1">Levering </div>
            <div class="w-18 border-l border-blue-300 px-2 py-1 font-bold text-blue-600 text-right">included</div>
          </div>
          <div class="bg-blue-100 rounded-md flex flex-row mb-1">
            <div class="w-12 px-1 py-1">
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-times"></i></span>
              <span class="text-blue-400 hover:text-blue-600 mx-1 cursor-pointer"><i class="fa fa-pen"></i></span>
            </div>
            <div class="w-48 border-l border-blue-300 px-2 py-1">Levering </div>
            <div class="w-18 border-l border-blue-300 px-2 py-1 font-bold text-blue-600 text-right">included</div>
          </div>
        </div>
      </div>


      <div class="rounded-lg border border-gray-100 px-2 pb-2 flex flex-row justify-between mt-2">
        <div class="flex flex-col">
          <div class="text-blue-700 text-xl font-bold py-2">Trade in</div>
          <div class="w-full flex flex-row justify-between">
            <div class="w-40">
              <img src="https://cdn.usedcars.center/1024x768/73/70/06/73700681581115578184257966404212466479471143132413392011108538528200740574587.jpg" />
            </div>
            <div class="p-2 flex flex-col text-xs">
              <div class="text-blue-500 text-base font-medium">Audi A4</div>
              <div class="text-gray-700 text-sm pb-2">2.0 TDI highline Sports turbo</div>
              <div class="text-gray-700">
                <i class="fal fa-tachometer-alt-fast text-gray-300"></i> <span class="ml-1 mr-2">114.000 km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-spreadsheet text-gray-300"></i> <span class="ml-1 mr-2">14/6/2020</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-gas-pump text-gray-300"></i> <span class="ml-1 mr-2">Benzine</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-joystick text-gray-300"></i> <span class="ml-1 mr-2">Manual</span>
              </div>
              <div class="text-gray-700">
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">4 doors</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">5 places</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">117 gr/km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">Euro 6</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-48 flex flex-col justify-between mt-2">
          <div class="flex flex-row justify-end"><div class="text-gray-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-lg px-2 p-1"><i class="fas fa-pen"></i></div></div>
          <div class="bg-blue-500 text-white flex flex-row rounded-tl-lg rounded-br-lg">
            <div class="pl-2 py-3 text-2xl leading-4">€</div>
            <div class="w-full p-2 flex flex-col text-right">
              <div class="text-xl leading-5 font-bold">14.900</div>
              <div class="leading-4 text-xs">incl.</div>
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-lg border border-gray-100 bg-gray-100 px-2 pb-2 flex flex-row justify-between mt-2">
        <div class="flex flex-col">
          <div class="text-blue-700 text-xl font-bold py-2">Insurance</div>
          <div class="w-full flex flex-row justify-between">

          </div>
        </div>
        <div class="w-48 flex flex-col justify-between mt-2">

          <div class="bg-blue-200 text-blue-600 flex flex-row rounded-tl-lg rounded-br-lg hover:bg-blue-400 font-medium cursor-pointer">
            <div class="pl-2 py-3 leading-4"><i class="fal fa-arrow-right"></i></div>
            <div class="w-full p-2 flex flex-col text-right">
              Start
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-lg border border-gray-100 bg-gray-100 px-2 pb-2 flex flex-row justify-between mt-2">
        <div class="flex flex-col">
          <div class="text-blue-700 text-xl font-bold py-2">Financing</div>
          <div class="w-full flex flex-row justify-between">

          </div>
        </div>
        <div class="w-48 flex flex-col justify-between mt-2">

          <div class="bg-blue-200 text-blue-600 flex flex-row rounded-tl-lg rounded-br-lg hover:bg-blue-400 font-medium cursor-pointer">
            <div class="pl-2 py-3 leading-4"><i class="fal fa-arrow-right"></i></div>
            <div class="w-full p-2 flex flex-col text-right">
              Start
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-lg border border-gray-100 bg-gray-100 px-2 pb-2 flex flex-row justify-between mt-2">
        <div class="flex flex-col">
          <div class="text-blue-700 text-xl font-bold py-2">Delivery</div>
          <div class="w-full flex flex-row justify-between">

          </div>
        </div>
        <div class="w-48 flex flex-col justify-between mt-2">

          <div class="bg-blue-200 text-blue-600 flex flex-row rounded-tl-lg rounded-br-lg hover:bg-blue-400 font-medium cursor-pointer">
            <div class="pl-2 py-3 leading-4"><i class="fal fa-arrow-right"></i></div>
            <div class="w-full p-2 flex flex-col text-right">
              Start
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="h-full w-1/3 bg-gray-50 p-2">
      <div class="rounded-lg bg-white p-2 w-full">
        <div class="flex flex-row justify-between">
          <div class="w-full font-bold text-blue-600 text-lg">Summary</div>
        </div>
        <div class="flex flex-row justify-between py-4 px-4">
          <div class="w-full font-bold text-gray-600 text-sm">Trade in </div>
          <div class="w-full font-bold text-gray-600 text-sm text-right">4 600 €</div>
        </div>
        <div class="flex flex-row justify-between pt-4 pb-2 px-4">
          <div class="w-full font-bold text-gray-600 text-sm">Sale </div>
        </div>
        <div class="flex flex-row justify-between py-1 px-4">
          <div class="w-full text-gray-400 text-sm">Vehicle price </div>
          <div class="w-full font-bold text-gray-600 text-sm font-bold text-right">14 900 €</div>
        </div>
        <div class="flex flex-row justify-between py-1 px-4">
          <div class="w-full text-gray-400 text-sm">Discount </div>
          <div class="w-full font-bold text-gray-600 text-sm font-bold text-right">500 €</div>
        </div>
        <div class="flex flex-row justify-between pt-1 pb-2 px-4">
          <div class="w-full text-gray-400 text-sm">Products & services </div>
          <div class="w-full font-bold text-gray-600 text-sm font-bold text-right">220 €</div>
        </div>
        <div class="flex flex-row justify-between pt-2 pb-4 px-4">
          <div class="w-full font-bold text-gray-600 text-sm">Total price vehicle </div>
          <div class="w-full font-bold text-gray-600 text-sm text-right">14 620 €</div>
        </div>
        <div class="flex flex-row justify-between items-center px-4 py-4 bg-blue-700 text-white">
          <div class="w-full font-bold text-sm">Amount to pay</div>
          <div class="w-full font-bold text-lg text-right">10 020 €</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Proposal"
}
</script>

<style scoped>

</style>