<template>
  <div class="flex flex-row w-full sticky top-0 z-20 bg-white p-2">
    <div class="rounded bg-blue-100 text-blue-700 p-2 w-1/3">
      <div class="flex flex-row justify-between">
        <div class="w-full font-bold text-lg">Lead</div>
        <div class="text-blue-600 bg-blue-300 hover:bg-blue-500 cursor-pointer hover:text-white rounded-md px-2 p-1"><i class="fas fa-pen"></i></div>
      </div>
      <div class="pt-1">
        <span class="font-bold">Online</span><br/>
        18/10/2020 14:56
      </div>
    </div>
    <div class="rounded-lg bg-gray-50 p-2 ml-2 w-1/3">
      <div class="flex flex-row justify-between">
        <div class="w-full font-bold text-blue-600 text-lg">Individual</div>
        <div class="text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-phone"></i></div>
        <div class="ml-2 text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-envelope"></i></div>
        <div class="ml-2 text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-pen"></i></div>
      </div>
      <div class="pt-1">
        <div class="pb-2 mb-2 border-b border-gray-200">
          Michael Devenijn<br/>
          Ten drieen 8<br/>
          9820 Bottelare<br/>
        </div>
        +32 478 75 89 23<br/>
        +32 9 298 0 299<br/>
      </div>
    </div>
    <div class="rounded-lg bg-gray-50 p-2 ml-2 w-1/3">
      <div class="flex flex-row justify-between">
        <div class="w-full font-bold text-blue-600 text-lg">Organisation</div>
        <div class="text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-phone"></i></div>
        <div class="ml-2 text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-envelope"></i></div>
        <div class="ml-2 text-blue-400 bg-gray-50 hover:text-blue-600 hover:bg-blue-100 cursor-pointer rounded-md px-2 p-1"><i class="fas fa-pen"></i></div>
      </div>
      <div class="pt-1">
        <div class="pb-2 mb-2 border-b border-gray-200">
          Autraalis BV<br/>
          BE0536650124<br/>
          Hundelgemsesteenweg 575<br/>
          9820 Merelbeke<br/>
        </div>
        +32 9 298 0 299<br/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesHeader"
}
</script>

<style scoped>

</style>