<template>
  <div class="relative h-full pr-2 flex flex-col w-full" style="max-width:1830px;">
    <div class="w-full flex flex-row justify-between items-center bg-blue-100 mb-2 py-2 pr-2 pl-4 z-10">
      <div class="font-bold text-blue-700">Find your specific info</div>
      <div class="text-blue-600 p-2 px-4 border-2 border-blue-600 rounded-lg hover:bg-blue-200 cursor-pointer font-bold">124 cars &nbsp; <i class="fal fa-arrow-right"></i></div>
    </div>
    <div class="h-full w-full flex flex-row">
      <div class="w-full flex flex-col overflow-y-auto overflow-x-hidden">
        <div class="w-full flex flex-wrap">
          <div class="w-1/3 flex flex-col pr-1">
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-4">
              <div class="pb-2"><span class="text-lg font-bold  text-blue-800">Sepcifications</span></div>
              <div class="font-bold pb-2">Price</div>
              <div class="flex flex-row">
                <div class="w-1/2 flex-1 min-w-0">
                  <input type="number" placeholder="From" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-l">
                </div>
                <div class="-ml-px flex-1 min-w-0">
                  <input type="number" placeholder="To" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-r">
                </div>
              </div>
              <div class="font-bold pt-4">First use</div>
              <div class="flex flex-row">
                <select  class="w-1/2 mt-1 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded">
                  <option></option>
                  <option>2011</option>
                  <option>2012</option>
                  <option>2013</option>
                </select>
                <select class="w-1/2 ml-1 mt-1 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded">
                  <option></option>
                  <option>2017</option>
                  <option>2018</option>
                </select>
              </div>
              <div class="font-bold pt-4">Mileage</div>
              <div class="flex flex-row">
                <select  class="w-1/2 mt-1 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded">
                  <option></option>
                  <option>2011</option>
                  <option>2012</option>
                  <option>2013</option>
                </select>
                <select class="w-1/2 ml-1 mt-1 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded">
                  <option></option>
                  <option>2017</option>
                  <option>2018</option>
                </select>
              </div>
              <div class="font-bold pt-4">Power Hp.</div>
              <div class="flex flex-row">
                <div class="w-1/2 flex-1 min-w-0">
                  <input type="number" placeholder="From" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-l">
                </div>
                <div class="-ml-px flex-1 min-w-0">
                  <input type="number" placeholder="To" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-r">
                </div>
              </div>
              <div class="font-bold pt-4">Emission CO<sub>2</sub></div>
              <div class="flex flex-row">
                <div class="w-1/2 flex-1 min-w-0">
                  <input type="number" placeholder="From" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-l">
                </div>
                <div class="-ml-px flex-1 min-w-0">
                  <input type="number" placeholder="To" class="w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded-r">
                </div>
              </div>
              <div class="font-bold pt-4">Emission norm</div>
              <div class="flex flex-row">
                <select  class="w-1/2 mt-1 w-full block sm:text-sm sm:leading-tight font-bold text-blue-600 border-gray-300 rounded">
                  <option></option>
                  <option>2011</option>
                  <option>2012</option>
                  <option>2013</option>
                </select>
              </div>
              <div class="font-bold pt-4">Doors</div>
              <div class="flex flex-row">
                    <span class="relative z-0 inline-flex shadow-sm rounded-md w-full">
                         <button type="button" class="w-1/4 relative inline-flex justify-center items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                           All
                         </button>
                         <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                           2/3
                         </button>
                         <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                           4/5
                         </button>
                         <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                           6/7+
                         </button>
                    </span>
              </div>
              <div class="font-bold pt-4">Places</div>
              <div class="flex flex-row">
                  <span class="relative z-0 inline-flex shadow-sm rounded-md w-full">
                       <button type="button" class="w-1/4 relative inline-flex justify-center items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                         All
                       </button>
                       <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                         2/3
                       </button>
                       <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                         4/5
                       </button>
                       <button type="button" class="w-1/4 -ml-px relative inline-flex justify-center items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                         6/7+
                       </button>
                  </span>
              </div>
            </div>
          </div>
          <div class="w-1/3 flex flex-col px-1">
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class="pb-2"><span class="text-lg font-bold text-blue-800">Make</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Audi</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Volkswagen   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Seat</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Skoda   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Porsche   </div>
            </div>
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold  text-blue-800">Model</span></div>
              <div class="font-bold py-2">Volkswagen</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Tiguan </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> T-roc </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Passat CC</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Touran</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Golf</div>
              <div class="font-bold py-2">Audi</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A1 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A3 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A4 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A5 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A6 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A7 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> A8 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q2 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q3 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q4 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q5 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q7 </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Q8 </div>
            </div>
          </div>
          <div class="w-1/3 flex flex-col pl-1">
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold text-blue-800">Vehicle type</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> New</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Demo   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Used cars</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Pre registration</div>
            </div>
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold text-blue-800">Body type</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Sedan</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Break   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Monovolume</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> SUV / Pickup   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Coupé   </div>
            </div>
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold text-blue-800">Fuel type</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Sedan</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Break   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Monovolume</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> SUV / Pickup   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Coupé   </div>
            </div>
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold text-blue-800">Gear type</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Sedan</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Break   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Monovolume</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> SUV / Pickup   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Coupé   </div>
            </div>
            <div class="p-4 border border-gray-200 flex flex-col rounded-lg mb-2">
              <div class=""><span class="text-lg font-bold text-blue-800">Covering</span></div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Sedan</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Break   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Monovolume</div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> SUV / Pickup   </div>
              <div><input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded mr-2"> Coupé   </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="hidden h-full overflow-y-auto overflow-x-hidden pb-32" style="min-width:710px;width:710px;">
        <div class="flex flex-col w-full">
          <div v-for="item in range(1,20)" v-bind:key="item" class="w-full border border-gray-200 flex flex-row justify-between rounded-lg mb-4">
            <div class="w-40">
              <img class="rounded-l-lg" src="https://cdn.usedcars.center/1024x768/73/70/06/73700681581115578184257966404212466479471143132413392011108538528200740574587.jpg" />
            </div>
            <div class="p-2 flex flex-col text-xs">
              <div class="text-blue-500 text-xl font-bold">Audi A4</div>
              <div class="text-gray-700 text-sm pb-2">2.0 TDI highline Sports turbo</div>
              <div class="text-gray-700">
                <i class="fal fa-tachometer-alt-fast text-gray-300"></i> <span class="ml-1 mr-2">114.000 km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-spreadsheet text-gray-300"></i> <span class="ml-1 mr-2">14/6/2020</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-gas-pump text-gray-300"></i> <span class="ml-1 mr-2">Benzine</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-joystick text-gray-300"></i> <span class="ml-1 mr-2">Manual</span>
              </div>
              <div class="text-gray-700">
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">4 doors</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-file-alt text-gray-300"></i> <span class="ml-1 mr-2">5 places</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">117 gr/km</span><span class="mr-2 text-gray-300">|</span>
                <i class="fal fa-burn text-gray-300"></i> <span class="ml-1 mr-2">Euro 6</span>
              </div>
            </div>
            <div class="w-32 flex flex-col justify-between">
              <div class="w-full flex flex-row justify-end pr-2">Demo</div>
              <div class="bg-blue-200 text-blue-600 flex flex-row rounded-tl-lg rounded-br-lg">
                <div class="pl-2 py-3 text-2xl leading-4">€</div>
                <div class="w-full p-2 flex flex-col text-right">
                  <div class="text-xl leading-4 font-bold">14.900</div>
                  <div class="leading-3 text-xs">incl.</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash';

export default {
  name: "Catalog",
  methods: {
    range(start, stop) {
      return _.range(start, stop);
    }
  }
}
</script>

<style scoped>

</style>